import React from 'react'
import Deal from '../Deal/Deal';

export default function PopularProduct() {

    const pageData = [
        { id: 1, navigate: 'LED_TV', heading: 'LED TV' },
        { id: 2, navigate: 'Air_Conditioner', heading: 'Air Conditioner' },
      ];

  return (
    <>
    {pageData.map((page) => (
        <Deal
          key={page.id}
          heading={page.heading}      
          navigate={page.navigate}
        />
      ))} 
    </>
  )
}

export const SampleNextArrow = (props) => {
   const { onClick } = props
    return (
      <div className='control-btn' onClick={onClick}>
        <button className='next'>
          <i class='fa fa-long-arrow-alt-right'></i>
        </button>
      </div>
    )
  }
export  const SamplePrevArrow = (props) => {
    const { onClick } = props
    return (
      <div className='control-btn' onClick={onClick}>
        <button className='prev'>
          <i class='fa fa-long-arrow-alt-left'></i>
        </button>
      </div>
    )
  }
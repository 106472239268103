import React from "react"
import Home from "../components/MainPage/Home"
import FlashDeals from "../components/flashDeals/FlashDeals"
import NewArrivals from "../components/newarrivals/NewArrivals"
// import  book png
import shopaholic from "../assests/heading/shopaholic.png"
import product from "../assests/heading/product.png"
import tag from "../assests/heading/tag.png"

const Pages = () => {
  
  return (
    <div className="ProductPage">
      <Home  />
      <FlashDeals heading="Popular List" imgData={shopaholic}  navigate={"Popular"} text="Popular Product"/>    
      <NewArrivals />
      <FlashDeals heading="Trending List" imgData={product}  navigate={"Trending"} text="Trending Product"/>    
      <FlashDeals heading="Featured List" imgData={tag}  navigate={"Featured"} text="Featured Product"/>    
    </div>
  )
}

export default Pages

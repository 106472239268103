const PData = [
    {  
      id:1,  
      img: "./images/ItemsIcon/1.png",
      PName: "Refrigerator",
      NoOfProduct: "10+ Products",
      link: "Refrigerator",
    },
    {  
      id:2,  
      img: "./images/ItemsIcon/2-removebg-preview.png",
      PName: "Mixer Grinder",
      NoOfProduct: "12+ Products",
      link: "Juicer_mixer",
    },
    {  
      id:3,  
      img: "./images/ItemsIcon/3-removebg-preview.png",
      PName: "Washing Machine",
      NoOfProduct: "28+ Products",
      link: "Washing_Machine",
    },
    {  
      id:4,  
      img: "./images/ItemsIcon/4-removebg-preview.png",
      PName: "Air Conditioner",
      NoOfProduct: "15+ Products",
      link: "Air_Conditioner",
    },
    {  
      id:5,  
      img: "./images/ItemsIcon/5-removebg-preview.png",
      PName: "LED TV",
      NoOfProduct: "25+ Products",
      link: "LED_TV",
    },
    {  
      id:6,  
      img: "./images/ItemsIcon/7-removebg-preview.png",
      PName: "Microwave",
      NoOfProduct: "8+ Products",
      link: "Microwave",
    },
    {  
      id:7,  
      img: "./images/ItemsIcon/8-removebg-preview.png",
      PName: "Speaker",
      NoOfProduct: "11+ Products",
      link: "Speaker",
    },
    {  
      id:8,  
      img: "./images/ItemsIcon/11-removebg-preview.png",
      PName: "Water Purifier",
      NoOfProduct: "8+ Products",
      link: "Water_Purifier",
    },
    {  
      id:9,  
      img: "./images/ItemsIcon/10-removebg-preview.png",
      PName: "CookTops",
      NoOfProduct: "9+ Products",
      link: "CookTop",
    },
    {  
      id:10,  
      img: "./images/ItemsIcon/12-removebg-preview.png",
      PName: "Chimneys",
      NoOfProduct: "16+ Products",
      link: "Chimneys",
    },
    
    
    
      
  ]
  export default PData
  
  
  
// apiUtils.js
export const fetchAPIdata = async (url) => {
    try {
      const res = await fetch(url);
      const findData = await res.json();
      if (Array.isArray(findData.data)) {
        return findData.data;
      } else {
        console.log("Invalid data format received");
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  
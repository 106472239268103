import React from "react";
import "./ProductStyle.css";
import PData from "./PData";
import {Link} from 'react-router-dom';

export default function ProductData() {
  return (
    <section className="Product_Data">
      <div className="container g_flex">
        {PData.map((value) => (
          <div className="Product_Box" key={value.id}>
            <Link to={value.link}>
              <div className="img_Box">
                <img src={value.img} alt="no img" />
              </div>
              <h6 className="Product_heading">{value.PName}</h6>
              <p className="Product_num">{value.NoOfProduct}</p>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

import React from "react";
import FlashCard from "./FlashCard";
import { Link } from "react-router-dom";

const FlashDeals = ({heading, imgData, navigate,text }) => {
  return (
    <>
      <section className='shop flash'>
        <div className='container'>
          <div className='heading d_flex'>
          <div className='heading-left f_flex'>
              <img src={imgData} alt="no img"/>
              <h1>{heading}</h1>
            </div>
            <Link to={`/${navigate}`} className='heading-right '>
              <span>View all</span>
              <i class='fa-solid fa-caret-right'></i>
            </Link>            
          </div>
          <FlashCard  value={text}/>
        </div>
      </section>
    </>
  )
}

export default FlashDeals

const Tdata = [
  {
    para: "Rahul Sharma",
    desc: "I recently purchased a refrigerator from CSD Parshotam Electronics Shop, and I'm amazed at how well it keeps my food fresh and organized. The cooling is excellent, and it perfectly suits my family's needs. Thank you for the quality product!",
  },
  {
    para: "Priya Sharma",
    desc: "I'm delighted with the LED TV I bought from CSD Parshotam Electronics Shop. The picture quality is outstanding, and the wide range of channels and smart features provide endless entertainment options. It's the centerpiece of my living room now!",
  },
  {
    para: "Amit Khanna",
    desc: "My experience with CSD Parshotam Electronics Shop was fantastic. I got a washing machine that has made laundry a breeze. Its various wash modes and efficiency have simplified my life. I'm a happy and satisfied customer!",
  },
  {
    para: "Neha Gupta",
    desc: "I recently upgraded my kitchen with a juicer mixer from CSD Parshotam Electronics Shop. It's become an essential part of my daily routine, helping me create delicious and healthy beverages effortlessly. Thank you for the wonderful product!",
  },
  {
    para: "Rajesh Kumar",
    desc: "The iron I purchased from CSD Parshotam Electronics Shop has been a game-changer. It heats up quickly and glides smoothly, making ironing a quick task. I appreciate the quality and reliability of the products at the store.",
  },
];

export default Tdata;

import React from "react";
import { useData } from "../../APIutils/DataContext";
import ProductCard from "../../common/MostCommon/ProductCard";

const CatCardMain = () => {
  const data = useData(); // Fetch the data from the context  
  
  const startIndex = data.length >= 16 ? data.length - 16 : 0;

  return (
    <>      
       {
        data.slice(startIndex).map((data, index) =>(                          
          <ProductCard data={data} index={index} key={data.Timestamp}/>         
          ))      
      }
    </>
  );
};

export default CatCardMain;

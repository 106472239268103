import React from 'react';
import "./MainLoader.css";
import logo1 from "../../assests/Parshotam-removebg-preview.png";

const MainLoader = () => {
  return (
    <div className='loading'>
            <img src={logo1} style={{height: '20vh', width: 'auto'}} className='imgg absolute top-[30%]' alt='no img'/>
        <div></div>
    </div>
  )
}

export default MainLoader
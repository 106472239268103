// DataContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAPIdata } from "./ApiUtils";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const APIdata = 'https://script.google.com/macros/s/AKfycbwMuduSgZ9ibzy8G49Ji89Wt8neMNN0XoSW7FmYpfDHLyUKpld1utMV9bTe31xTmRQZJg/exec';

  useEffect(() => {
    fetchAPIdata(APIdata)
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [APIdata]);

  return (
    <DataContext.Provider value={data}>{children}</DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};

import React from "react"
import "./topStyle.css"
import TopCart from "./TopCart"

const TopCate = () => {
  return (
    <>
      <section id='TopCate'>
        <div className='container'>
          <div className='heading e_flex'>
            <div className='heading-common heading-top'>
              <h1>Our Testimonials</h1>
            </div>           
          </div>
          <TopCart />
        </div>
      </section>
    </>
  )
}

export default TopCate

import img1 from '../../assests/MainBanner/refrigerator.png';
import img2 from '../../assests/MainBanner/speakers-removebg-preview.png';
import img3 from '../../assests/MainBanner/tv-led-removebg-preview.png';
import img4 from '../../assests/MainBanner/washingmachine-removebg-preview.png';

const Sdata = [
  {
    id: 1,
    title: "Explore the Latest Electronics Deals",
    desc: "Discover a wide selection of high-quality electronics at unbeatable prices. From cutting-edge gadgets to must-have accessories, we have it all.",
    cover: img1,
    link: "Refrigerator",
  },
  {
    id: 2,
    title: "Top Electronics Best Sellers on Sale",
    desc: "From timeless classics to trending tech, our collection of best-selling electronics is now available at discounted rates. Find your favorites today.",
    cover: img2,
    link: "AllProducts",
  },
  {
    id: 3,
    title: "Your One-Stop Electronics Superstore",
    desc: "Navigate our user-friendly platform to explore a diverse range of electronic products. Shop by category, brand, or features, and experience seamless shopping.",
    cover: img3,
    link: "LED_TV",
  },
  {
    id: 4,
    title: "Start Buying New Electronics with Ease",
    desc: "Connecting buyers and sellers directly, our platform ensures transparent communication. List your electronics, interact with buyers, and get the best value.",
    cover: img4,
    link: "Washing_Machine",
  },
];

export default Sdata;

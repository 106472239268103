import React from "react"
import "./style.css"
import src1 from "../../assests/banners/quotes/th.webp";
import src2 from "../../assests/banners/quotes/th2.webp";
import src3 from "../../assests/banners/quotes/th3.webp";
import src4 from "../../assests/banners/quotes/th4.webp";
import src5 from "../../assests/banners/quotes/th5.webp";
import src6 from "../../assests/banners/quotes/th6.webp";

const Wrapper = () => {
  
  return (
    <>
      <section className='wrapper'>
        <div className='container'>
          
          <h1 className="headingMain">Our Inspiration</h1>
              
          <div className="imgContain g_flex ">
           <div className="productImg">
             <img  src={src1} alt="no img" /> 
           </div>
           <div className="productImg">
             <img  src={src2} alt="no img" /> 
           </div>
           <div className="productImg ">
             <img  src={src3} alt="no img" /> 
           </div>
           <div className="productImg imgHide">
             <img  src={src4} alt="no img" /> 
           </div>
           <div className="productImg imgHide">
             <img  src={src5} alt="no img" /> 
           </div>
           <div className="productImg imgHide">
             <img  src={src6} alt="no img" /> 
           </div>

          </div>      
            
          
          
        </div>
      </section>
    </>
  )
}

export default Wrapper

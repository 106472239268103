const AboutCustomer = [
  {
    img: "fa-solid fa-truck-ramp-box",
    name: "Worldwide Delivery",
    Text: "Relish Timely Delivery to Your Doorstep with Affordable Charges",
  },
  {
    img: "fa-brands fa-intercom",
    name: "Exceptional Support",
    Text: "Experience Outstanding Customer Care via Calls and Dedicated Online Assistance",
  },
  {
    img: "fa-brands fa-dropbox",
    name: "Assured Quality",
    Text: "Embrace Our Unwavering Commitment to Elevated Quality Standards for All Our Products",
  },
  {
    img: "fa-solid fa-people-carry-box",
    name: "Fresh Designs",
    Text: "Stay Up-to-Date with Our Always Trending Selections, Curated Just for You",
  },
];

export default AboutCustomer;

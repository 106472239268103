import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "./common/header/Header.jsx";
import Footer from "./common/footer/Footer.jsx";
import Pages from "./pages/Pages.jsx";
import Shop from "../src/components/shops/Shop.jsx";
import { DataProvider } from "./APIutils/DataContext";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import HomePage from "./pages/HomePage";
// Import the useData hook from the DataContext
import { useData } from "./APIutils/DataContext";
import MainLoader from "./common/MainLoader/MainLoader";

//for go above when click on new route
function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return unlisten;
  }, [history]);

  return null;
}

const routeData = [
  { path: "/AllProducts", text: "All Products" },
  { path: "/Popular", text: "Popular Product" },
  { path: "/Trending", text: "Trending Product" },
  { path: "/Featured", text: "Featured Product" },
  { path: "/Refrigerator", text: "Refrigerator" },
  { path: "/LED_TV", text: "LED TV" },
  { path: "/Washing_Machine", text: "Washing Machine" },
  { path: "/Microwave", text: "Microwave" },
  { path: "/Chimneys", text: "Chimneys" },
  { path: "/Speaker", text: "Speaker" },
  { path: "/Air_Conditioner", text: "Air Conditioner" },
  { path: "/CookTop", text: "CookTop" },
  { path: "/Grinder", text: "Grinder" },
  { path: "/Cooler", text: "Cooler" },
  { path: "/Juicer_mixer", text: "Juicer mixer Grinder" },
  { path: "/Toaster", text: "Toaster" },
  { path: "/Geysers", text: "Geysers" },
  { path: "/Iron", text: "Iron" },
  { path: "/Water_purifier", text: "Water Purifier" },
  { path: "/Dishwasher", text: "Dishwasher" },
];

function App() {
  // for right-click disable
  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", disableRightClick);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  const data = useData(); // Fetch the data from the context

  const [isLoaded, setIsLoaded] = useState(false);
  // window.addEventListener("load", () => {
    setTimeout(() => {
      setIsLoaded(true);
    // console.log("loaded");      
    }, 2000);
    
  // });

  return (
    <>
      {!isLoaded ? (
        <MainLoader/>
      ) : (
        <>
          <Router>
            <ScrollToTop />
            <Header />
            <DataProvider>
              <Switch>
                <Route path="/" exact>
                  <HomePage />
                </Route>
                <Route path="/product" exact>
                  <Pages />
                </Route>
                <Route path="/about" exact>
                  <About />
                </Route>
                <Route path="/contact" exact>
                  <Contact />
                </Route>
                {/* create single product routes     */}
                {routeData.map((item) => (
                  <Route key={item.path} path={item.path} exact>
                    <Shop value={item.text} data={data} />
                  </Route>
                ))}
              </Switch>
            </DataProvider>
            <Footer />
          </Router>
        </>
      )}
    </>
  );
}

export default App;

import React from 'react';
import HomeMain from '../components/HomeMain/HomeMain';
import Wrapper from '../components/wrapper/Wrapper';
import Annocument from "../components/annocument/Annocument";
import TopCate from "../components/top/TopCate";
import Popular from "../components/Popular/Popular"
import ProductData from '../components/ProductData/ProductData';
import CategoryMain from '../components/categories/CategoryMain';


export default function HomePage() {
  return (
    <>
        <HomeMain/>
        <ProductData/>
        <Annocument />
        <CategoryMain/>
        <Popular/>
        <TopCate /> 
        <Wrapper/>
    </>
  )
}

import React, { useState } from "react";
import placeholder from "../../assests/no-image.png";
import WhatsAppLink from "./Whatsapp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useIntersectionObserver } from "../../APIutils/useIntersectionObserver";

export default function SliceCard({ data, index }) {
  const { targetRef, isIntersecting } = useIntersectionObserver({
    threshold: 0.2, // Adjust the threshold as needed
  });

  // for handle image error
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  // for update the card colors 
  const cardIndex = index;
  // console.log(cardIndex);
  const cardColor = Math.floor(cardIndex / 4) % 2 === 0 ? 'green' : 'yellow';

  return (
    <>
      <div className={`box ${isIntersecting ? "visible" : ""}`} ref={targetRef}>
        <div className={`product mtop ProductCard ${cardColor}`}>
          <div className="img e_flex">
            <span className="discount">{data.Company_Name}</span>
            <span className="ptype">{data.Product_Type}</span>
            <a
              href={data.Product_Image.replace("/open?id=", "/uc?id=")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={
                  imageError
                    ? placeholder
                    : data.Product_Image.replace("/open?id=", "/uc?id=")
                }
                alt="no img"
                placeholderSrc={placeholder}
                effect="blur"
                className="lazy-image popular"
                onError={handleImageError}
              />
            </a>
          </div>
          <div className="product-details">
            <h4>{data.Add_tag}</h4>  
            <h3>{data.Product_Name}</h3>          
            <div className="rate">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
            
            
            <div className="price">
              {/* whatsapp component  */}
              <WhatsAppLink
                productName={data.Product_Name}
                companyName={data.Company_Name}
                productType={data.Product_Type}
                productImage={data.Product_Image}
              />
              <button>
                <i class="fa-regular fa-heart"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import placeholder from "../../assests/no-image.png";
import WhatsAppLink from "./Whatsapp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useIntersectionObserver } from "../../APIutils/useIntersectionObserver";

export default function SliceCard({ data }) {
  const { targetRef, isIntersecting } = useIntersectionObserver({
    threshold: 0.2, // Adjust the threshold as needed
  });

  // for handle image error
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <div className={`box ${isIntersecting ? "visible" : ""}`} ref={targetRef}>
        <div className="product mtop PopularProductCard">
          <div className="img e_flex">
            <a
              href={data.Product_Image.replace("/open?id=", "/uc?id=")}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={
                  imageError
                    ? placeholder
                    : data.Product_Image.replace("/open?id=", "/uc?id=")
                }
                alt="no img"
                placeholderSrc={placeholder}
                effect="blur"
                className="lazy-image popular"
                onError={handleImageError}
              />
            </a>
          </div>
          <div className="product-details">
            <h4>{data.Company_Name}</h4>
            <h3>{data.Product_Name}</h3>
            <div className="price">
              {/* whatsapp coponent  */}
              <WhatsAppLink
                productName={data.Product_Name}
                companyName={data.Company_Name}
                productType={data.Product_Type}
                productImage={data.Product_Image}
              />
              <button>
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import "./style.css";
import {Link} from 'react-router-dom';
import logo from "../../assests/Parshotam-removebg-preview.png" ;


 
const Footer = () => { 

  return (
    <>
      <footer>
        <div className='container g_flex'>
          <div className='box about'>
            <img src={logo } alt="no img" className='img'/>
            <p>Welcome to Parshotam Electronics, Canteen Store Department (CSD),  your go-to destination for the latest and most reliable electronic products. With a commitment to serving the needs of our esteemed armed forces personnel and their families.</p>
            
          </div>

          <div className='box '>           
            <ul>             
              <li><Link className="link" to="/Refrigerator">Refrigerator</Link></li>              
              <li><Link className="link" to="/LED_TV">LED TV</Link></li>              
              <li><Link className="link" to="/Washing_Machine">Washing machine</Link></li>              
              <li><Link className="link" to="/Microwave">Microwave</Link></li>              
              <li><Link className="link" to="/Chimneys">Chimneys</Link></li>              
              <li><Link className="link" to="/Speaker">Speaker</Link></li>              
              <li><Link className="link" to="/Air_Conditioner">Air Conditioner</Link></li>
              <li><Link className="link" to="/CookTops">CookTops</Link></li>
            </ul>
          </div>
          <div className='box'>            
            <ul>
              <li><Link className="link" to="/Grinder">Grinder</Link>  </li>
              <li><Link className="link" to="/Cooler">Cooler</Link> </li>
              <li><Link className="link" to="/Juicer_mixer">Juicer mixer</Link> </li>
              <li><Link className="link" to="/Toaster">Toaster</Link></li>
              <li><Link className="link" to="/Geysers">Geysers</Link></li>
              <li><Link className="link" to="/Iron">Iron</Link></li>
              <li><Link className="link" to="/Water_purifier">Water purifier</Link></li>
              <li><Link className="link" to="/Dishwasher">Dishwasher</Link></li>
            </ul>
          </div>
          <div className='box about'>            
            <ul>
              <li><span className="gold">Phone : </span>+91 9469200872 </li>
              <li><span className="gold">Email : </span> parshotamelectronics@gmail.com</li>
              <li><span className="gold">Address : </span> National Highway, Bari-Brahmana, near Canara Bank, Bari Brahmana-181133</li>
            </ul>
          </div>
        </div>       
       
      </footer>


      <div className="footer_last ">
        <div className="footer_Data container">
        <div className="credit">
        Created By <a href="https://www.linkedin.com/in/harshvardhan614" target="_blank" rel="noreferrer"> Harshvardhan Rathore</a>
      </div>
      <div className="rights">
        Copyright © <span className='gold'>Parshotam Electronics</span> 2023 All Rights Reserved
      </div>
        </div>    
    </div>
    </>
  )
}

export default Footer

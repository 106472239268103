import React from "react";
import "./contactStyle.css";
import location from '../../assests/Contact/location.png'
import email from '../../assests/Contact/email.png'
import call from '../../assests/Contact/phoneCall.png'
import img1 from '../../assests/Contact/facebook.png'
import img2 from '../../assests/Contact/instagram.png'
import img3 from '../../assests/Contact/linkedin.png'
import img4 from '../../assests/Contact/pinterest.png'
import img5 from '../../assests/Contact/twitter.png'

export default function Contact() {
  return (
    <div id="contact">
      <div className="container">
        <div className="contactData">
          <div className="contactInfo">
            <div>
                <h2>Contact Info</h2>
                <ul class="info">
                    <li>
                        <span><img src={location} alt="no-img"  /></span>
                        <span>National Highway, Bari-Brahmana, near Canara Bank, Bari Brahmana-181133</span>
                    </li>
                    <li>
                        <span><img src={email} alt="no-img"  /></span>
                        <span>parshotamelectronics@gmail.com</span>
                    </li>
                    <li>
                        <span><img src={call} alt="no-img"  /></span>
                        <span>+91 9469200872 , <br />+91 9419210458 , <br />+91 7006983852</span>
                    </li>
                </ul>
            </div>
            <ul className="sci">
              <li><a href="/"><img src={img1} alt="no img" /></a></li>
              <li><a href="/"><img src={img2} alt="no img" /></a></li>
              <li><a href="/"><img src={img3} alt="no img" /></a></li>
              <li><a href="/"><img src={img4} alt="no img" /></a></li>
              <li><a href="/"><img src={img5} alt="no img" /></a></li>
            </ul>
          </div>
          <div className="contactForm">
          <h2> Send a Message</h2>
     <div class="formbox">
        <div class="inputBox w50">
            <input type="text"  required />
            <span>
                First Name
            </span>
        </div>
        <div class="inputBox w50">
            <input type="text"  required />
            <span>
                Last Name
            </span>
        </div>
        <div class="inputBox w50">
            <input type="text"  required />
            <span>
                Email Address
            </span>
        </div>
        <div class="inputBox w50">
            <input type="text"  required />
            <span>
                Mobile Number
            </span>
        </div>
        <div class="inputBox w100">
            <textarea required></textarea>
            <span>
                Write your message here...
            </span>
        </div>
        <div class="inputBox w100">
            <button type="sumbit" value="Send" >Send Message</button>
        </div>

     </div>
          </div>
        </div>

        <div className="map-part">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26876.116186963765!2d74.88278176585034!3d32.645749593834815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e9d9671bced97%3A0xc110248c9abe9f0f!2sPARSHOTAM%20ELECTRONICS!5e0!3m2!1sen!2sin!4v1692742889861!5m2!1sen!2sin"           
            style={{ border: "0" }}
            loading="lazy"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import DealCard from "./DealCard";
import "../Popular/popularStyle.css"
import { Link } from "react-router-dom";

export default function Deal({ heading,  navigate }) {
  return (
    <>
      <section className="shop flash">
        <div className="container">
          <div className="heading d_flex">
            <div className="heading-left f_flex">
              <div className="heading-common head-popular">
                <h1>{heading}</h1>
              </div>
            </div>
            <Link to={`/${navigate}`} className="heading-right ">
              <span>View all</span>
              <i class="fa-solid fa-caret-right"></i>
            </Link>
          </div>
          <DealCard navigate={navigate} />
        </div>
      </section>
    </>
  );
}

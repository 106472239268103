import React from "react";
import ProductCard from "../../common/MostCommon/ProductCard";
import { useData } from "../../APIutils/DataContext"; 
import Loader from "../../common/Loader/Loader"; 

const ShopCart = ({ value }) => {
  const data = useData(); // Fetch the data from the context
  let filteredRecords;

  if (value === "All Products") {
    filteredRecords = data; 
  }else if(value === "Popular Product"){
    filteredRecords = data.filter((item) => item.Add_tag === value);
  }else if(value === "Trending Product"){
    filteredRecords = data.filter((item) => item.Add_tag === value);
  }else if(value === "Featured Product"){
    filteredRecords = data.filter((item) => item.Add_tag === value);
  }else {
    filteredRecords = data.filter((item) => item.Product_Type === value);
  }

  // Reverse the data array
  const reversedData = filteredRecords.slice().reverse();

  return (
    <>
      {data.length === 0 ? (        
        <Loader />
      ) : (
        <>
        {reversedData.length === 0 ? (
          <p>Currently, no products are available for this category.</p>
        ) : (
          <>
            {reversedData.map((data, index) => (
              <ProductCard data={data} key={data.Timestamp} index={index}/>
            ))}
          </>
        )}
      </>
      )}
    </>
  );
};

export default ShopCart;

import React from "react";
import ShopCart from "./ShopCart";
import ProductFilter from "../../common/MostCommon/Filter";

const Shop = ({value, data}) => {
  return (
    <>
      <section className='shop background'>
        <div className='container'>
          <div>
            <div className='heading-common shopHeading'>                           
                <h1>{value}</h1>                        
            </div>
            <div className="filter">
              <ProductFilter products={data}/>
            </div>
            <div className='product-content '>
              <ShopCart value={value} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Shop

import React from "react";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useData } from "../../APIutils/DataContext";
import SliceCard from "../../common/MostCommon/SliceCard";
import Loader from "../../common/Loader/Loader";


export default function DealCard({navigate}) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          
        ],
        nextArrow: null, // Hide the next arrow button
        prevArrow: null,
      }
    
    const data = useData(); // Fetch the data from the context      


    const filteredRecords = data.filter(data => navigate === "LED_TV"
     ? data.Product_Type === "LED TV" : data.Product_Type === "Air Conditioner"
      );

    return (
        <>
          {data.length === 0 ? (
      <Loader/>
    ) : (
      <Slider {...settings}>
        {filteredRecords.slice(0, 10).map((data) => (
          <SliceCard data={data} key={data.Timestamp} />
        ))}
      </Slider>
    )}
        </>
      )
}

import React from 'react'
import './Home.css'
import SliderHome from "./Slider"

export default function HomeMain() {
  return (
    <div className='homecolor'>
        <div className="container">
            <div className="homemain">
                <SliderHome />  
            </div>
        </div>
    </div>
  )
}

import { useEffect, useRef, useState } from "react";

export function useIntersectionObserver(options) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const targetRef = useRef();
  const targetRefCurrent = targetRef.current; // Create a stable reference

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setIsIntersecting(entries[0].isIntersecting);
      },
      options
    );

    if (targetRefCurrent) { // Use the stable reference
      observer.observe(targetRefCurrent);
    }

    return () => {
      if (targetRefCurrent) { // Use the stable reference
        observer.unobserve(targetRefCurrent);
      }
    };
  }, [options, targetRefCurrent]);

  return { targetRef, isIntersecting };
}

import React from "react"
import './announcementStyle.css';
// import images 
import img1 from "../../assests/Brands/Whirlpool.png";
import img2 from "../../assests/Brands/Blue_Star.webp";
import img3 from "../../assests/Brands/panasonic.png";
import img4 from "../../assests/Brands/godej.webp";
import img5 from "../../assests/Brands/samsung.png";
import img6 from "../../assests/Brands/sony.png";


const Annocument = () => {

  return (
    <>
      <section id='annocument'>
        <div className='container '>
          <div className="heading-common head-annou">
            <h1>Top Brands</h1>
          </div>
          <div className="img-box">
          <div className='img'>
            <img src={img1} alt="no img"/>
          </div>  
          <div className='img'>
            <img src={img2} alt="no img"/>
          </div>  
          <div className='img'>
            <img src={img3} alt="no img"/>
          </div>  
          <div className='img'>
            <img src={img4} alt="no img"/>
          </div>  
          <div className='img'>
            <img src={img5} alt="no img"/>
          </div>  
          <div className='img'>
            <img src={img6} alt="no img"/>
          </div>  
          </div>
                
          
        </div>
      </section>
    </>
  )
}

export default Annocument

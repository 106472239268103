import React from "react"
import PData from "../ProductData/PData"

const Cart = () => {
  return (
    <>
      <div className='content arrival g_flex product'>
        {PData.slice(0,6).map((val, index) => {
          return (
            <div className='box' key={index}>
              <div className='img'>
                <img src={val.img} alt='no img' />
              </div>
              <h3>{val.PName}</h3>
              <span>{val.NoOfProduct}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Cart

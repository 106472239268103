import React from "react";
import CatCardMain from "./CatCardMain";
import { Link } from "react-router-dom";

const CategoryMain = () => {
  return (
    <>
      <section className='CatProduct shop background'>
        <div className='container '>
          <div>
          <div className="heading d_flex">
            <div className="heading-left f_flex">
              <div className="heading-common">
                <h1>Latest Products</h1>
              </div>
            </div>
            <Link to="AllProducts"className="heading-right ">
              <span>View all</span>
              <i class="fa-solid fa-caret-right"></i>
            </Link>
          </div>
            <div className='product-content '>
              <CatCardMain />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CategoryMain

import React from "react"
import indian_flag from "../../assests/indian_flag.webp";

const Head = () => {
  
  return (
    <>
      <section className='head'>
        <div className='container d_flex'>
          <div className='left'> 
            <label>  
            <span>Welcome to</span> India’s best Canteen Store Department                       
            </label>
          </div>
          <div className='right'>
            <label>              
                Need Help?                          
             </label> 
             <label>
              <a rel='noopener noreferrer' target="_blank"
              href="mailto:parshotamelectronics@gmail.com"
              >
                parshotamelectronics@gmail.com
              </a> 
            </label>          
            <label ><img src={indian_flag} alt="no img" /></label>           
          </div>
        </div>
      </section>
    </>
  )
}

export default Head

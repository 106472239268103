import React from "react";
import "./FilterStyle.css";

const ProductFilter = () => {
  // const [selectedFilter, setSelectedFilter] = useState("");

  // const handleFilterChange = (filter) => {
  //   setSelectedFilter(filter);
  // };

  // const filter = (products, selectedFilter) => {
  //   const filterValue = selectedFilter[selectedFilter];
  //   switch (selectedFilter) {
  //     case "Price":
  //       return products.filter((product) => product.price === filterValue);
  //     case "Product Type":
  //       return products.filter((product) => product.type === filterValue);
  //     case "Featured Product":
  //       return products.filter((product) => product.featured === filterValue);
  //     case "Company":
  //       return products.filter((product) => product.company === filterValue);
  //     case "Alphabetically":
  //       return products.filter(
  //         (product) => product.name.toLowerCase() < filterValue
  //       );
  //     default:
  //       return products;
  //   }
  // };

  // filter(products, selectedFilter);

  return (
    <div className="product-filter">
      <h3>Filter Products:</h3>
      <div className="filter-buttons">
        {/* Price filter */}
        <div className="price-filter select-box">
          <select
            name="price"
            // value={selectedFilter[selectedFilter]}
            // onChange={(e) => handleFilterChange("Price", e.target.value)}
          >
            <option >Price</option>
            <option value="lowToHigh">Low to High</option>
            <option value="highToLow">High to Low</option>
          </select>
        </div>

        {/* Company filter */}

        <div className="company-filter select-box">
          <select
            name="company"
            // value={selectedFilter[selectedFilter]}
            // onChange={(e) => handleFilterChange("company", e.target.value)}
          >
            <option value="all">Company</option>
            <option value="samsung">Samsung</option>
            <option value="whirlpool">Whirlpool</option>
            <option value="glen">glen</option>
            <option value="sony">Sony</option>
            <option value="panasonic">Panasonic</option>
            <option value="bluestar">Bluestar</option>
            <option value="ifb">IFB</option>
            <option value="Godrej">Godrej</option>
            <option value="llyod">Llyod</option>
            <option value="other">Other</option>
          </select>
        </div>

        {/* Featured filter */}
        <div className="featured-filter select-box">
          <select
            name="featured"
            // value={selectedFilter[selectedFilter]}
            // onChange={(e) => handleFilterChange("featured", e.target.value)}
          >
            <option value="all">All Products</option>
            <option value="trending">Trending</option>
            <option value="popular">Popular</option>
            <option value="featured">Featured</option>
          </select>
        </div>

        {/* Alphabetical filter */}

        <div className="alphabetical-filter select-box">
          <select
            name="alphabetical"
            // value={selectedFilter[selectedFilter]}
            // onChange={(e) => handleFilterChange("alphabetical", e.target.value)}
          >
            <option >Alphabetically</option>
            <option value="aToZ">A to Z</option>
            <option value="zToA">Z to A</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;

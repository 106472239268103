import React from "react"
import { Link } from "react-router-dom";

const Categories = () => {

  const CatData = [ 
    { path: "/Refrigerator", text: "Refrigerator" },
    { path: "/LED_TV", text: "LED TV" },
    { path: "/Washing_Machine", text: "Washing Machine" },
    { path: "/Microwave", text: "Microwave" },
    { path: "/Chimneys", text: "Chimneys" },
    { path: "/Speaker", text: "Speaker" },
    { path: "/Air_Conditioner", text: "Air Conditioner" },
    { path: "/CookTop", text: "CookTop" },
    { path: "/Grinder", text: "Grinder" },
    { path: "/Cooler", text: "Cooler" },
  ];
  

  return (
    <>
      <div className='category'>
        {CatData.map((value, index) => {
          return (
            <Link className='box f_flex' key={index} to={value.path}>
              <span>{value.text}</span>
            </Link>
          )
        })}
      </div>
    </>
  )
}

export default Categories

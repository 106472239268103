import React from 'react';
import './popularStyle.css';
import PopularProduct from "./PopularProduct";

export default function Popular() {
  return (
    <section className='PopularProduct'>
      <div className="container">
        <PopularProduct/>
      </div>
    </section>
  )
}

import React from "react";
import "./AboutStyle.css";
import imgBig from "../../assests/AboutUs/csd2.jpg";
import imgCsd from "../../assests/AboutUs/csd1.jpg";
import AboutCustomer from "./AboutCustomer";
import img1 from "../../assests/AboutUs/Parshotam_Kumar_Gupta.jpg"
import img2 from "../../assests/AboutUs/rajatGupta.jpg"

export default function About() {
  return (
    <div id="about">
      <div className="container">
         <div class="about-content about-content-2">
          <div class="about2-right">
            <img src={imgCsd} alt="no img" />
            <p class="title">KNOW ABOUT US</p>
            <h2>
              Parshotam Electronics: India’s Best canteen store department
            </h2>
          </div>

          <div class="left about2-left">
            <img src={imgBig} alt="no img" />
          </div>
         </div>
         <section id="about-data">
          <p class="data-1">
            Welcome to Parshotam Electronics, Canteen Store Department (CSD),
            your go-to destination for the latest and most reliable electronic
            products. With a commitment to serving the needs of our esteemed
            armed forces personnel and their families, we take pride in offering
            a wide range of cutting-edge electronics at competitive prices.
          </p>
          <p className="data-1">
            At CSD Electronic Shop, we understand the importance of staying
            up-to-date with the latest technological advancements. We carefully
            curate our inventory to include top brands and the most sought-after
            gadgets. From smartphones and laptops to home appliances and
            entertainment systems, our collection is designed to cater to
            diverse preferences and requirements
          </p>
          <p class="data-2">
            We are honored to serve our armed forces' dedicated men and women
            and their families. As Parshotam Electronics, we aim to provide a
            trusted and convenient shopping destination that meets all your
            electronic needs. Join us on this exciting journey and experience
            the best electronic innovation with CSD Electronic Shop.
          </p>
         </section>
         <section className="about-customer">
          {AboutCustomer.map((CustomData) => (
            <div className="customer-box" >
              <i class={CustomData.img}></i>
              <h3>{CustomData.name}</h3>
              <p>{CustomData.Text}</p>
            </div>
          ))}
         </section>
        <section className="founder-about">
          <div className="heading">
            <h1>OUR FOUNDERS</h1>
          </div>          
           <div class="my-about-container">
            <div class="about-row about-row-2">
                <div  class="about-text about-width-1">
                    <div class="my-about-sub-title">
                        <div class="my-about-line"></div>
                        <p>OUR FOUNDER</p>
                    </div>
                    <div class="my-about-title">
                        <p>Parshotam Gupta</p>
                    </div>
                    <div class="my-about-text">
                        <p>
                        At Parshotam Electronics, our core values of convenience, sustainability, and community engagement define our mission. As the founder, I'm proud to lead a company that prioritizes making electronic shopping seamless for armed forces personnel and families. Our commitment to sustainable products reflects our dedication to a better future. By fostering community connections, we create an environment where our customers feel valued and supported. In essence, Parshotam Electronics isn't just a store; it's a reflection of our commitment to serving those who serve our nation with excellence and care.
                        </p>
                    </div>
                </div>                
                <div class="my-about-image about-width-2">
                    <img class="img1" src={img1} alt="about us" />
                </div>
                
            </div>
            </div>           
           <div class="my-about-container">
            <div class="about-row">
            <div class="my-about-image about-width-2">
              <img class="img1" src={img2} alt="about us" />
            </div>
            <div  class="about-text about-width-1">
                    <div class="my-about-sub-title">
                        <div class="my-about-line"></div>
                        <p>OUR FOUNDER</p>
                    </div>
                    <div class="my-about-title">
                        <p>Rajat Gupta</p>
                    </div>
                    <div class="my-about-text">
                        <p>
                        As the owner of Parshotam Electronics, our vision is to create a customer-centric shopping experience by offering a diverse range of high-quality electronic products at CSD prices. We aim to streamline the shopping process with modern technologies and prioritize sustainability. Through community engagement and employee development, we envision CSD as a trusted and preferred destination for armed forces personnel and their families, providing exceptional service and exceeding customer expectations.
                        </p>
                    </div>
            </div>  
                
            </div>
            </div>           
        </section>
      </div>
    </div>
  );
}

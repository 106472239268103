import React from "react";

const WhatsAppLink = ({ productName, companyName, productType, productImage }) => {
  const confirmBeforeOpeningWhatsApp = (event) => {
    
    let userName = prompt("Please enter your name:");

    // If the user cancels the prompt or leaves the name empty, use "Sir/Madam" as the default
    if (userName === null || userName.trim() === "") {
      userName = "Sir/Madam";
    }

    // Construct the WhatsApp message
    const message = `Hello! I am interested in this product: ${productImage}\n\nProduct Name: ${productName}\nCompany Name: ${companyName}\nProduct Type: ${productType}`;

    const confirmed = window.confirm(`Great choice, ${userName}! Would you like to inquire about this product: "${productName}"?`);

    if (!confirmed) {
      event.preventDefault();
    }  

    // Create a WhatsApp-compatible URL
    const whatsappMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+919149991212&text=${whatsappMessage}`;

    // Open WhatsApp in a new tab with the customized message
    window.open(whatsappUrl);

    // Prevent the default link behavior
    event.preventDefault();
  };

  return (
    <div
      className="LinkNumber"
      onClick={confirmBeforeOpeningWhatsApp}
    >
      Check Now
    </div>
  );
};

export default WhatsAppLink;
